import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgSwanLogo from '../img/logo_swan.jpg'
import imgPeacockLogo from '../img/logo_peacock.jpg'

const Page = () => (
  <Layout pageName="contact">
    <SEO title="Contact / Visit" />
    <div id="primary" className="content-area">
      <main id="main" className="site-main" role="main">
        <article id="page-contact" className="post-45 page type-page status-publish hentry">


          <div className="home-authors">
            <ul>
              <li>
                <ul className="horizontal">
                  <li className="author-link">
                    <div className="link-replace">
                      <span className="author-name">For appointments in Oxted</span>
                      <div><img alt="Oxted &amp; Dorking Swan logo" src={imgSwanLogo} /></div>
                      <div className="address">
                                <span className="address">
                                    <span className="address-line">Pipers Dental Practice</span>
                                    <span className="address-line">42a Station Road East</span>
                                    <span className="address-line">Oxted</span>
                                    <span className="address-line">Surrey RH8 0PG</span>
                                </span>
                        <span className="telephone">Tel: 01883 712 486</span>
                        <span className="email"><a href="mailto:piperdental@btconnect.com">piperdental@btconnect.com</a></span>
                      </div>
                    </div>
                  </li>
                  <li className="author-link">
                    <div className="link-replace">
                      <span className="author-name">For appointments in Dorking</span>
                      <div><img alt="Oxted &amp; Dorking Peacock logo" src={imgPeacockLogo} /></div>
                      <div className="address">
                                <span className="address">
                                    <span className="address-line">Dorking Dental Centre</span>
                                    <span className="address-line">71 Dene Street</span>
                                    <span className="address-line">Dorking</span>
                                    <span className="address-line">Surrey RH4 2DP</span>
                                </span>
                        <span className="telephone">Tel: 01306 879 993</span>
                        <span className="email"><a
                          href="mailto:info@dorkingdental.com">info@dorkingdental.com</a></span>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </article>
      </main>
    </div>
  </Layout>
)

export default Page